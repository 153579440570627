var runOnPage = 'body.category';
var triggerInfiniteScrollElmt = '.load_wrapper .button.primary';
var seeMoreContainerElmt = '.load_wrapper';
//var pageLoaderElmt = '.load_wrapper #page_loader'; //Not applicable for Messegue
//var paginationElmt = '.pagination'; //Not applicable for Messegue

if (document.body.classList.contains('mobile')) {
    // Load mobile version
    var headerWrapperElmt = '.banner_wrapper';
    var itemContainerElmt = '#list_item .item_container';
    var resetElmt = '.menu_part.bottom a';
    var categoryFormId = '#filters_form';
    //var paginationWrapperElmt = '#pagination_content'; //Not applicable for Messegue    
    var seeMoreElmt = '.pagerNav.end_pagi .loader_scroll';
    var deviceLoadDelay = 500;
    var scrollSpacer = 40;

} else {
    // Load desktop version
    var headerWrapperElmt = '#site_head_wrap';
    var itemContainerElmt = '#list_item .item';
    var resetElmt = '.wrapper_menu .container_categories a';
    var categoryFormId = '#filter_sticky';
    //var paginationWrapperElmt = '.pager_wrapper'; //Not applicable for Messegue 
    var seeMoreElmt = '.load_wrapper .loader_scroll';
    var deviceLoadDelay = 500;
    var scrollSpacer = 50;
}

// Check if element is in view port
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Activate/Desactivate infinite scroll
function initInfiniteScroll(activate = false) {
    activate = false; // Force false
    
    if (activate == true) {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
        $(seeMoreElmt).css({'opacity': 0}) ;
        sessionStorage.setItem("infiniteScroll", true);
    } else {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
        sessionStorage.setItem("infiniteScroll", false);
    }
}

// Run when inifite scroll activated to check if first item or "See all" button is in view port to trigger next/previous page load
function handleScroll() {
    const isLoading = $('#is_loading').val();
    const maxItems = $('#totalElems').val();
    const productLoaded = $(itemContainerElmt+':not(".push")').length;
    const button = $(triggerInfiniteScrollElmt);
    const firstItem = $(itemContainerElmt).first(); // Get first item on the list
    const lastItem = $(itemContainerElmt).last(); // Get last item on the list
    const nextTopPage = parseInt($('#top_page').val()) - 1; // Get next top page to load
    const nextBottomPage = parseInt($('#bottom_page').val()) + 1; // Get bottom page to load

    if (isLoading == 0 && productLoaded < maxItems) {
        // Continue infinite scroll if loading action complete and not all items loaded
        if (isInViewport(firstItem) && nextTopPage > 0) {
            // If first item is visible and top page not 1, load next top page
            paginationGoToPage(nextTopPage, "top");
        } else if (isInViewport(button)) {
            // If "See all" button visible, load next page
            paginationGoToPage(nextBottomPage, "bottom");

        }
    } else if (productLoaded == maxItems) {
        // Stop inifinite scroll as all items loaded
        initInfiniteScroll(false);
        sessionStorage.setItem("infiniteScroll", true); // But keep session active for page reload or accessing page from back button
    }
}

// Goes to given page
// Works with pager in shop districts
function paginationGoToPage(page = 1, scrollDirection) {
    const productLoaded = $(itemContainerElmt+':not(".push")').length;
    const maxItems = $('#totalElems').val();

    const isLastPage = ((page-1) >= Math.ceil(maxItems / parseInt($('#nb_prod').val()))) ? true : false;
    const allProductLoaded = (productLoaded == maxItems) ? true : false;

    if (scrollDirection !== undefined && (isLastPage || allProductLoaded)) {
        // Stop infinite scroll if last page reached or all items loaded on page
        if (!allProductLoaded) {
            // All top pages not loaded, continue infinite scroll
            initInfiniteScroll(true);
        } else {
            // All pages loaded, stop infinite scroll
            initInfiniteScroll(false);
        }

        // Do not process further
        return false;
    }

    if (scrollDirection == "top") {
        //$(pageLoaderElmt).show(); //Not applicable for Messegue
        $('#top_page').val(page);
        initInfiniteScroll(true);
    } else if (scrollDirection == "bottom") {
        //$(pageLoaderElmt).show(); //Not applicable for Messegue
        $('#bottom_page').val(page);
        initInfiniteScroll(true);
    } else {
        // Both field uses same page reference
        $('#top_page').val(page);
        $('#bottom_page').val(page);

        // "See all" button must be visible
        $(seeMoreElmt).fadeTo(100, 1);

        // Remove infinite scroll eventLister when normal pagination
        initInfiniteScroll(false);

        setTimeout(() => {
            // Scroll to top
            if ($(runOnPage).length) {
                $(document).scrollTop(0);
            }
        }, 1000);
    }

    let nextPage = parseInt(page - 1);

    if (scrollDirection == "top") {
        var fromScrollTop = true;
    } else if (scrollDirection == "bottom") {
        var fromScrollBottom = true;
    }

    if ($('body.mobile').length) {
        var type = document.getElementById('type_tri').value;
        var idObj = getCurrentIdRayon();

        if (from_type == 'product') {
            generateNewBlocProd(nextPage, type, idObj, null, null, fromScrollBottom, fromScrollTop, true);
        } else {
            generateNewBlocSearch(document.getElementById('nb_prod').value, nextPage, null, fromScrollBottom, fromScrollTop);
        }
    } else {
        // Si on est sur la page de recherche
        if ($('#search_page').length > 0) {
            generateNewBlocSearch(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop, fromScrollBottom);
        } else {
            generateNewBlocProd(nextPage, null, null, null, null, fromScrollBottom, fromScrollTop, true);
        }     
    }
}

function paginate(page) {
    if ($(categoryFormId).length == 0) {
        return;
    }

    var $form = $(categoryFormId);
    var total_element = parseFloat($form.find("input[name='totalElems']").val());;
    var nb_prod = parseFloat($form.find("input[name='nb_prod']").val());
    var nb_total_page = Math.ceil(total_element / nb_prod);
    //var pagination = $(paginationWrapperElmt); //Not applicable for Messegue

    var btnScrollInfini = $(seeMoreElmt);
    btnScrollInfini.show();

    page = page || 1;

    $.ajax({
        url: path_relative_root + "ajax_reload_pagination.php",
        type: "get",
        data: {
            page: page,
            link: "paginationGoToPage()",
            nb_total_page: nb_total_page,
        },
        success: function(res) {
            //pagination.html(res); //Not applicable for Messegue

            if (res == "") {
                // Hide "See all" button as only one page available
                $(seeMoreElmt).css({'opacity': 0}) ;
            }
        },
        complete: function () {
            $(seeMoreContainerElmt).css({'opacity': 1}) ; // Restore opacity for item autoscroll
            $(seeMoreElmt).css({'opacity': 1}) ; // Restore opacity for see more button

            //$(paginationElmt).attr("data-totalpage", nb_total_page); //Not applicable for Messegue

            //if (page < nb_total_page) { //Not applicable for Messegue
                //$(paginationElmt).attr('data-pagenum', page + 1); //Not applicable for Messegue
            //} //Not applicable for Messegue

            if ($('body.mobile').length) {
                if ($("#twoProducts").hasClass("actif")){
                    $(itemContainerElmt).removeClass("full");
                } else {
                    $(itemContainerElmt).addClass("full");
                }
            }

            setTimeout(function() {
                var $swipers = $('.productSwiper');

                $swipers.each(function(index, container) {
                    var $container = $(container);
                    
                    if ($container.find('.productSwiper').length === 0 && 
                        $container.find('.swiper-wrapper').length && 
                        $container.find('.swiper-slide').length > 1) {
                        var productSwiper = new Swiper(container, {
                            slidesPerView: 1,
                        });
                    }
                });
            }, 500);
        }
    });
}

function updateLoadedGauge() {
    var $form = $(categoryFormId);
    var total_element = parseInt($form.find("input[name='totalElems']").val());
    var nb_prod = parseInt($form.find("input[name='nb_prod']").val()) * parseInt($("#bottom_page").val());

    if (nb_prod > total_element) {
        $('.nbProdDisplayed').html(total_element + ' ' + Translator.translate('pagerTxt') + ' ' + total_element);
        $('.loadedGauge').html('<progress value="' + total_element + '" max="' + total_element + '"></progress>');
    } else {
        $('.nbProdDisplayed').html(nb_prod + ' ' + Translator.translate('pagerTxt') + ' ' + total_element);
        $('.loadedGauge').html('<progress value="' + nb_prod + '" max="' + total_element + '"></progress>');
    }

    if (nb_prod >= total_element) {
        $('.loader_scroll').hide();
    }
}

function addClickTrigger(actualPage) {
    $(itemContainerElmt).each(function() {
        let dataAnchor = $(this).find('.anchor_ref').attr('id');
        let onclickValue = $(this).attr("onClick");

        if (dataAnchor !== undefined && (onclickValue === undefined || !$(this).attr("onClick").includes('saveItemData'))) {
            onclickValue = (onclickValue === undefined) ? "" : onclickValue;            
            $(this).attr('onclick', "saveItemData('"+actualPage+"','"+dataAnchor+"');"+onclickValue);
        }
    });
}

function saveItemData (dataPage, dataAnchor) {
    updateUrlParameter('page', dataPage);
    updateUrlParameter('anchor', dataAnchor);
}

function updateUrlParameter(param, value) {
    // Create a URL object from the current window location
    var url = new URL(window.location.href);

    // Use URLSearchParams to modify the query parameters
    var searchParams = url.searchParams;

    if (param == "anchor") {
        // Construct new URL with anchor
        var newUrl = window.location.href.split('#')[0] + '#'+value;

        // Update the URL without reloading
        history.pushState(null, '', newUrl);
    } else if (param == "page" && value == 1) {
        // Remove the specified parameter
        url.searchParams.delete(param);
        
        // Update the browser's URL without reloading the page
        window.history.replaceState({}, document.title, url);
    } else if (searchParams.has(param) || sessionStorage.getItem("infiniteScroll") === "true") {
        // Set or update the parameter
        searchParams.set(param, value);

        // Update the browser's address bar without reloading the page
        window.history.replaceState({}, '', url.toString());
    }
}

// Save infinite scroll last session state on page load
let initScrollToState = sessionStorage.getItem("infiniteScroll");

$(document).ready(function () {
    if ($(runOnPage).length) {
        const productLoaded = $(itemContainerElmt+':not(".push")').length;
        const maxItems = parseInt($('#totalElems').val());

        if (productLoaded < maxItems) {
            // "See all" button must be visible when loading page for first time by default
            $(seeMoreElmt).fadeTo(100, 1);
        }

        // Handle "See all" click to trigger infinite scroll
        $(seeMoreElmt).on('click', function () {
            $(this).addClass('loading');

            const productLoaded = $(itemContainerElmt+':not(".push")').length;
            const nextPage = parseInt($('#bottom_page').val()) + 1;

            if (productLoaded < maxItems) {
                paginationGoToPage(nextPage, "bottom");
            }
        });

        // Store clicked product page reference
        addClickTrigger($('#bottom_page').val());

        setTimeout(() => {
            var anchor = window.location.hash;

            if (anchor) {
                // Smooth scroll to the target element
                var targetElement = $(anchor);

                if (targetElement.length) {
                    var headerHeight = $(headerWrapperElmt).outerHeight(); // Adjust selector as needed for the header
                    var targetPosition = $(targetElement).offset().top - (headerHeight + scrollSpacer); // Calculate target position

                    // Remove the anchor from the URL after scrolling
                    history.replaceState(null, null, ' '); // This removes the hash

                    $('html, body').animate({
                        scrollTop: targetPosition
                    }, 1300); // Duration of the scroll
                }
            }
        }, deviceLoadDelay);

        if (initScrollToState === "true") {
            // Restore infinite scroll if previously present
            setTimeout(() => {
                initInfiniteScroll(true);
            }, 1000);
        }
    }

    setTimeout(() => {
        $(resetElmt).on('click touchstart', function () {
            if (!$(this).attr("onClick")) {
                // Disable scroolToProduct function
                eraseCookie('p_id');
                // Disable infinite scroll if page 1 is loaded else leave last choice on
                initInfiniteScroll(false);
            }
        });
    }, deviceLoadDelay);
});